<template>
  <v-container>
    <live-payment v-if="!paid"></live-payment>
    <v-row v-show="paid">
      <v-col cols="12" align="center" justify="center">
        <div >
          <video-player class="player"
          ref="videoPlayer"
          :options="playerOps"
          @ready="onPlayerReady"
          @suspend="suspended($event)"
          >
          </video-player>
        </div>
      </v-col>
      <v-col cols="12"><span class="subtitle-1 font-weight-black">{{title}}</span></v-col>
      <v-col cols="12"><span class="body-2"> {{description}} </span></v-col>
      <v-col cols="12">
        <v-btn rounded color="#5CDB95" @click="goLive">
          <v-icon color="red" left>mdi-play</v-icon>Go to live
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-menu
            v-model="dmenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
            <template v-slot:activator="{ on }">
            <v-text-field 
            outlined
            v-model="date"
            label="Select date"
            v-on="on"
            
            readonly
            ></v-text-field>
            </template>
            <v-date-picker v-model="date" :max="max" :min="min" @input="dateSelected"></v-date-picker>
        </v-menu>
      </v-col>
      <!-- <v-col cols="12">
        <pre-payment></pre-payment>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col v-for="item in vlist.slice().reverse()" :key="item.id" cols="12">
        <v-card @click="selectVideo(item)">
            <v-row>
              <v-col cols="4" md="3" lg="2">
                <v-img class="ma-2" width="300px" :src="item.thumbnail" contain>
                  <span v-if="item.duration" style="border-radius: 7px;" class="black overline white--text pa-1">{{item.duration}}</span>
                </v-img>
              </v-col>
              <v-col cols="8" md="9" lg="10">
                <v-row>
                  <v-col cols="12">
                    <span class="subtitle-1 font-weight-black">{{item.name}}</span>
                  </v-col>
                  <v-col cols="12">
                    <span class="d-none d-sm-flex"> {{item.description}} </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import LivePayment from '@/components/LivePayment.vue'
  import {videoPlayer} from 'vue-videojs7'
  import 'videojs-contrib-quality-levels'
  import 'videojs-hls-quality-selector'
  import 'videojs-seek-buttons'
  import firebase from 'firebase/app'
  import 'firebase/database'
  export default {
    name: 'HelloWorld',
    data: () => ({
      title: '',
      description: '',
      vlist: [],
      date: '',
      dmenu: false,
      src: '',
      stream: {},
      valid: true,
      alreadyWatching: false,
      loading: true,
      playerOps: {
        autoplay: true,
        control: true,
        controlBar: {
            timeDivider: false,
            durationDisplay: false
          },
        plugins: {
          seekButtons: {
            forward: 10,
            back: 10,
            backIndex: 0 
          },
          },
        html5: {
          hls: {
            overrideNative: true
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false,
        }
      },
    }),
    components: {
      videoPlayer,
      LivePayment
    },
    computed: {
    player () {
      return this.$refs.videoPlayer.player
    },
    max () {
      let date = new Date
      return date.toISOString()
    },
    min () {
      let date = new Date
      date.setDate(date.getDate() - 31)
      return date.toISOString()
    },
    branch() {
      return this.$store.getters.loadedBranch
    },
    paid () {
      var payd = this.$store.getters.loadedPaymentDetails
      var d = new Date()
      var validityCheck = +payd.expires - d.getTime()
      if(payd.status == 'paid' && validityCheck >= 0){
          this.initiate()
        return true
      } 
      else {
        return false
      }      
    }
  },
  methods: {
    selectVideo (item) {
      this.title = item.name
      this.description = item.description
      this.playVideo(item.link)
    },
    goLive () {
      this.title = ''
      this.description = ''
      this.playVideo(this.src)
    },
    onPlayerReady () {
      this.player.fill(true)
    },
    playVideo (source) {
      const video = {
        withCredentials: false,
        type: 'application/x-mpegURL',
        src: source
      }
      
      this.player.reset() // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
      this.player.src(video)
      this.player.load()
    },
    initiate () {
      firebase.database().ref('online/sistreams').once('value')
      .then((data) => {
        this.stream = data.val()
        var src = this.stream.url + 'thls/' + this.stream.key + '.m3u8'
        this.src = src
        this.playVideo(src)
        this.loading = false
        this.player.hlsQualitySelector({
          displayCurrentQuality: true,
      })
      })
    },
    dateSelected () {
      this.vlist = []
      this.dmenu = false
      var date = new Date(this.date)
      var y = +date.getYear() - 100
      var m = +date.getMonth() + 1
      var d = date.getDate()
      if(m < 10) {
        m = '0' + m
      } 
      if(d < 10) {
        d = '0' + d
      } 
      var val = d.toString()+m.toString()+y.toString()
      firebase.database().ref('sivod/'+val).once('value')
      .then((data) => {
        var list = data.val()
        if(list) {
          for(let i in list) {
            var item = {
              id: i,
              name: list[i].name,
              link: `https://nrvod.ap-south-1.linodeobjects.com/${list[i].link}/file.m3u8`,
              thumbnail: `https://nrvod.ap-south-1.linodeobjects.com/${list[i].link}/thumbnail.jpg`,
              duration: list[i].duration ? new Date(+list[i].duration * 1000).toISOString().substr(11, 8) : '',
              description: list[i].description
            }
            this.vlist.push(item) 
          }
        }
      })
      //this.selectVideo()
    },
  },
  mounted () {
  }
  }
</script>

<style scoped>
  .player {
    width:100%;
    height: 300px !important;
  }
</style>
